import { MedicalDocument } from '@nodal/api/core';
import { FC } from 'react';

const flyerUrl =
  'https://nodal-public-assets.s3.us-east-1.amazonaws.com/nodal-pluto-health-services-flyer.pdf';
const privacyUrl =
  'https://nodal-public-assets.s3.us-east-1.amazonaws.com/nodal-notice-of-privacy-practices_pluto.pdf';

interface CareGapRecommendationsProps {
  medicalDocuments?: MedicalDocument[];
}

export const CareGapRecommendations: FC<CareGapRecommendationsProps> = ({
  medicalDocuments,
}) => {
  const url =
    medicalDocuments && medicalDocuments.length > 0
      ? medicalDocuments[0]?.content_url
      : null;

  return (
    <div className="p-8">
      <div className="flex flex-col gap-4 mx-auto max-w-7xl">
        <h1 className="text-3xl font-medium">Resources</h1>
        <p className="text-lg">
          Care Recommendations brought to you by our partner, Pluto Health.{' '}
          <a
            target="_blank"
            href={flyerUrl}
            className="text-inherit underline hover:opacity-80"
            rel="noreferrer"
          >
            Learn More
          </a>
        </p>
        {url && (
          <a target="_blank" href={url} rel="noreferrer" className="w-fit">
            <button className="py-3 px-6 text-white bg-[#2B4332] hover:bg-[#1e2f23] rounded-full transition-colors">
              View your care recommendations
            </button>
          </a>
        )}
        <a
          target="_blank"
          href={privacyUrl}
          className="text-inherit underline hover:opacity-80"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
      </div>
    </div>
  );
};
