import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { useQuery } from 'react-query';
import { CareGapRecommendations } from './CareGapRecommendations';

export const CareGapRecommendationsConnected = () => {
  const apiClient = useApiClient();

  const { data: medicalDocuments } = useQuery(
    queryKeys.medicalDocumentsList,
    () =>
      apiClient.api.MedicalReviewsApi.medicalReviewsMedicalDocumentsCareGapList(),
  );

  return <CareGapRecommendations medicalDocuments={medicalDocuments?.data} />;
};
